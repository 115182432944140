import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  hideOldPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group(
      {
        oldPasswordCtrl: new UntypedFormControl('', [Validators.required]),
        newPasswordCtrl: new UntypedFormControl('', [Validators.required]),
        confirmPasswordCtrl: new UntypedFormControl('', [Validators.required]),
      },
      { validators: this.matchPassword }
    );
  }

  matchPassword = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('newPasswordCtrl')?.value;
    const confirmPassword = control.get('confirmPasswordCtrl')?.value;
    if (newPassword !== confirmPassword && newPassword && confirmPassword) {
      return { not_match: true };
    }
    return null;
  };

  toggleOldPasswordHide = () => {
    this.hideOldPassword = !this.hideOldPassword;
  };

  toggleNewPasswordHide = () => {
    this.hideNewPassword = !this.hideNewPassword;
  };

  toggleConfirmPasswordHide = () => {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  };

  getErrorMsg = (fieldName: string) => {
    if (this.resetPasswordForm.get(fieldName)?.touched) {
      if (this.resetPasswordForm.get(fieldName)?.hasError('required')) {
        return 'Please enter required info';
      }
    }

    return '';
  };

  passwordNotMatch = () => {
    return this.resetPasswordForm.hasError('not_match');
  };

  submit = async () => {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.invalid) {
      return;
    }
    const data = {
      old_password: this.resetPasswordForm.get('oldPasswordCtrl')?.value,
      new_password: this.resetPasswordForm.get('newPasswordCtrl')?.value,
    };

    const reponse = await this._authService.resetPassword(data).toPromise();

    if (reponse) {
      this.dialogRef.close({ logout: 1 });
    }
  };

  cancel = () => {
    this.dialogRef.close();
  };
}
