<div>
  <div mat-dialog-title class="title-detail">
    <mat-icon svgIcon="check" class="me-2"></mat-icon>
    Payment Success
  </div>
  <div *ngIf="remainingDeposit != null" mat-dialog-content>
    Deposit amount left :
    <span class="ps-2 field-content"
      >{{ remainingDeposit | number : "1.2-2" }} USD</span
    >
  </div>
</div>
<div mat-dialog-actions align="end" class="mb-0 ml-1">
  <button
    mat-raised-button
    class="confirm-button px-5"
    [mat-dialog-close]="true"
    color="primary"
    routerLink="../../../"
  >
    Done
  </button>
</div>
