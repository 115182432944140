import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuildingService } from 'src/app/services/building.service';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RoomService } from 'src/app/services/room.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dialog-room-detail',
  templateUrl: './dialog-room-detail.component.html',
  styleUrls: ['./dialog-room-detail.component.scss'],
})
export class DialogRoomDetailComponent implements OnInit {
  env = environment;
  room: any;
  backgroundImage: string = '/assets/imgs/room-default-pic.jpg';
  action: string = '';
  contract: string = '';
  isOccupied: boolean = false;
  constructor(
    private buildingService: BuildingService,
    public dialogRef: MatDialogRef<DialogRoomDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private roomService: RoomService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'location',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/location.svg')
    );
    iconRegistry.addSvgIcon(
      'regular_building',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/imgs/regular_building.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'floor',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/floor.svg')
    );
    iconRegistry.addSvgIcon(
      'area',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/area.svg')
    );
    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/check.svg')
    );
    iconRegistry.addSvgIcon(
      'movein',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/move-in.svg')
    );
    iconRegistry.addSvgIcon(
      'moveout',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/move-out.svg')
    );
    iconRegistry.addSvgIcon(
      'view',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/eye.svg')
    );
    iconRegistry.addSvgIcon(
      'renew',
      sanitizer.bypassSecurityTrustResourceUrl('assets/imgs/renew.svg')
    );
    if (this.data.data.status === 'vacant') {
      this.action = 'in';
    } else {
      this.action = 'out';
      if (this.data.data.is_expired) {
        this.contract = 'renew';
      } else {
        this.contract = 'view';
      }
    }
    this.roomService.getRoom(this.data.data._id).subscribe((res: any) => {
      this.room = res;
      if (this.room.status === 'occupied') {
        this.isOccupied = true;
      }
      if (res.thumbnail) this.backgroundImage = this.getImage(res.thumbnail);
    });
  }

  ngOnInit(): void {}

  getImage(thumbnail: string) {
    return `${environment.api_url}/files/${thumbnail}`;
  }
  onMove(e: any) {
    this.dialogRef.close();
    let id = e._id;
    if (e.status === 'occupied') {
      // this.router.navigate([`move/move-out/${e.contract._id}`]);
      // this.router.navigate([`information/${id}/room-move-out`]);
      this.router.navigate([`move/room-move-out/${e.contract._id}`]);
    } else {
      // this.router.navigate([`move/move-in/${id}`]);
      this.router.navigate([`information/${id}/room-move-in`]);
    }
  }
  onSummary(e: any) {
    this.dialogRef.close();
    let id = e._id;
    if (this.contract === 'view' && !this.data.data.is_expired) {
      this.router.navigate([`information/contract/${e.contract._id}`]);
    } else {
      this.router.navigate([`move/renew/${e.contract._id}`]);
    }
  }
  onRoomOverview(_id: any) {
    this.dialogRef.close();
    this.router.navigate([`information/${_id}`]);
  }
  onClose() {
    this.dialogRef.close();
  }
}
