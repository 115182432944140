<div mat-dialog-title class="title-detail">
  <div>Detail</div>
  <button mat-icon-button color="warn" (click)="onClose()">
    <mat-icon> cancel </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="image-cover">
    <img src="{{ backgroundImage }}" class="img image-cover" />
  </div>
  <div class="room-content">
    <div class="room-title" (click)="onRoomOverview(room?._id)">
      <span class="room-name">Room #{{ room?.room_number }}</span>
      <span
        class="status"
        [ngClass]="room?.status === 'vacant' ? 'available' : 'occupied'"
      >
        {{ room?.status | titlecase }}
      </span>
    </div>
    <div>
      <ul class="address">
        <li>
          <mat-icon svgIcon="location" class="icon-size"></mat-icon>
          <span>
            {{ room?.location.name }}
          </span>
        </li>
        <li>
          <mat-icon svgIcon="regular_building" class="icon-size"></mat-icon>
          <span>
            {{ room?.building.name }}
          </span>
        </li>
        <li>
          <mat-icon svgIcon="floor" class="icon-size"></mat-icon>
          <span> Floor {{ room?.floor }} </span>
        </li>
        <li *ngIf="room?.width != null && room?.height != null">
          <mat-icon svgIcon="area"></mat-icon>
          <span> {{ room?.width }} x {{ room?.height }} </span>
        </li>
      </ul>
    </div>
    <div
      [ngStyle]="{ display: room?.facilities.length == 0 ? 'none' : 'block' }"
    >
      <ul class="facilities">
        <li *ngFor="let item of room?.facilities">
          <mat-icon svgIcon="check" class="icon-size"></mat-icon>
          <span>
            {{ item.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="tenant-info" *ngIf="isOccupied">
    <div class="tentant-title">Contract Information</div>
    <div class="profile-info">
      <img src="/assets/imgs/detault-user.png" width="60" height="60" />
      <div class="tenant-name">
        <div class="name">
          {{ room?.contract?.first_name }} {{ room?.contract?.last_name }}
        </div>
        <div class="phone-number">
          {{ room?.contract?.phone_number }}
        </div>
      </div>
    </div>
    <div class="row sub-info">
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <div class="sub-info-title">Contract ID</div>

        <div class="sub-info-name mt-2">
          {{ room?.contract?.code }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <div class="sub-info-title">Gender</div>
        <div class="sub-info-name mt-2">
          {{ room?.contract?.gender | titlecase }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <div class="sub-info-title">Phone Number</div>
        <div class="sub-info-name mt-2">
          {{ room?.contract?.phone_number }}
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-2">
        <div class="sub-info-title">Start End Contract</div>
        <div class="sub-info-name mt-2">
          {{ room?.contract?.start_date | date : "dd-MMM-y" }}
          <span *ngIf="room?.contract?.end_date">~</span>
          {{ room?.contract?.end_date | date : "dd-MMM-y" }}
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="footer" mat-dialog-actions>
  <div>
    <div class="footer-title">Rental Fee</div>
    <div class="price">{{ room?.price }} USD</div>
  </div>
  <div>
    <button
      *ngIf="room?.status !== 'vacant'"
      mat-raised-button
      class="move contract me-3"
      (click)="onSummary(room)"
      [ngClass]="data.data.is_expired ? 'renew' : 'view'"
    >
      <mat-icon
        class="icon-button"
        svgIcon="{{ contract }}"
        matTooltip="{{ contract | titlecase }} Contract"
      ></mat-icon>
      <span class="display-button">{{ contract | titlecase }} Contract</span>
    </button>
    <button
      mat-raised-button
      class="move"
      [ngClass]="room?.status === 'vacant' ? 'in' : 'out'"
      (click)="onMove(room)"
    >
      <mat-icon
        class="icon-button"
        svgIcon="movein"
        *ngIf="room?.status === 'vacant'"
        matTooltip="Move In"
      ></mat-icon>
      <mat-icon
        class="icon-button"
        *ngIf="room?.status !== 'vacant'"
        svgIcon="moveout"
        matTooltip="Move Out"
      ></mat-icon>
      <span class="display-button">Move {{ action }}</span>
    </button>
  </div>
</div>
