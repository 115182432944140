<mat-drawer-container >
	<mat-drawer
			[mode] = "drawerMode"
			[opened] = "sideBarOpen"
			(openedChange) = "drawerOpenChangeHandler($event)"
	>
		<div class = "logo-wrapper">
			<div class = "img-box">
				<img
						class = "img"
						src = "../../../assets/imgs/logo_B.png"
						alt = ""
						srcset = ""
				/>
			</div >
			<img src = "../../../assets/imgs/logo_w.png" alt = "" class = "img-in-text"/>
		</div >
		<mat-nav-list >
			<div *ngIf = "menu.superadmin.length > 0">
				<a
						*ngFor = "let item of menu.superadmin"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon
							svgIcon = "{{ item.icon }}"
							mat-list-icon
							*ngIf = "item.title !== 'information'"
					></mat-icon >
					<mat-icon mat-list-icon *ngIf = "item.title === 'information'">{{
                  item.icon
						}}
					</mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<mat-expansion-panel
					[expanded] = "false"
					*ngIf = "menu.moderation.length > 0"
			>
				<mat-expansion-panel-header >
					<mat-panel-title style = "color: #fff">
						<mat-icon svgIcon = "moderate"></mat-icon >
						<span class = "panel-title">{{ "moderate" | translate }}</span >
					</mat-panel-title >
				</mat-expansion-panel-header >
				<a
						mat-list-item
						*ngFor = "let item of menu.moderation"
						class = "expanel-item"
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<span style = "padding-left: 45px">{{ item.title | translate }}</span >
				</a >
			</mat-expansion-panel >
			<div *ngIf = "menu.information.length > 0">
				<a
						*ngFor = "let item of menu.information"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon
							svgIcon = "{{ item.icon }}"
							mat-list-icon
							*ngIf = "item.title !== 'information'"
					></mat-icon >
					<mat-icon mat-list-icon *ngIf = "item.title === 'information'">{{
                  item.icon
						}}
					</mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.income.length > 0">
				<a
						*ngFor = "let item of menu.income"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon
							class = "custom-icon"
							svgIcon = "income"
							mat-list-icon
					></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.expense.length > 0">
				<a
						*ngFor = "let item of menu.expense"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon
							class = "custom-icon"
							svgIcon = "expense"
							mat-list-icon
					></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.request.length > 0">
				<a
						*ngFor = "let item of menu.request"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "request" mat-list-icon></mat-icon >
					<span >
            {{ item.title | translate }}
          </span >
					<div class = "spacer"></div >
					<span class = "badge">{{ totalRequests }}</span >
				</a >
			</div >
			<div *ngIf = "menu.contract.length > 0">
				<a
						*ngFor = "let item of menu.contract"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "contract" mat-list-icon></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.announcement.length > 0">
				<a
						*ngFor = "let item of menu.announcement"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "announcement" mat-list-icon></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.accountant.length > 0">
				<a
						*ngFor = "let item of menu.accountant"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon
							class = "custom-icon"
							svgIcon = "{{ item.icon }}"
							mat-list-icon
					></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.reports.length > 0">
				<a
						*ngFor = "let item of menu.reports"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "{{ item.icon }}" mat-list-icon></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.properties.length > 0">
				<a
						*ngFor = "let item of menu.properties"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "{{ item.icon }}" mat-list-icon></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<div *ngIf = "menu.clients.length > 0">
				<a
						*ngFor = "let item of menu.clients"
						mat-list-item
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<mat-icon svgIcon = "{{ item.icon }}" mat-list-icon></mat-icon >
					<span >{{ item.title | translate }}</span >
				</a >
			</div >
			<mat-expansion-panel [expanded] = "false" *ngIf = "menu.settings.length > 0">
				<mat-expansion-panel-header >
					<mat-panel-title style = "color: #fff">
						<mat-icon >settings</mat-icon >
						<span class = "panel-title">{{ "settings" | translate }}</span >
					</mat-panel-title >
				</mat-expansion-panel-header >
				<a
						mat-list-item
						*ngFor = "let item of menu.settings"
						class = "expanel-item"
						[routerLink] = "item.route"
						routerLinkActive = "list-item-active"
						(click) = "onClickMenu()"
				>
					<span style = "padding-left: 45px">{{ item.title | translate }}</span >
				</a >
			</mat-expansion-panel >
		</mat-nav-list >
		<p class = "version">v1.1.3</p >
	</mat-drawer >
	<mat-drawer-content >
		<div
				class = "content nav-padding"
				[class.sided] = "drawerMode === 'side' && sideBarOpen"
		>
			<mat-toolbar >
				<mat-toolbar-row class = "justify-content-between">
					<div class = "d-flex flex-row align-items-center">
						<button
								class = "toggle-btn"
								mat-icon-button
								(click) = "sideBarToggler()"
								color = "primary"
						>
							<svg
									width = "19"
									height = "15"
									viewBox = "0 0 19 15"
									fill = "none"
									xmlns = "http://www.w3.org/2000/svg"
							>
								<path
										fill-rule = "evenodd"
										clip-rule = "evenodd"
										d = "M0.75 1.25C0.75 0.918479 0.881696 0.600537 1.11612 0.366117C1.35054 0.131696 1.66848 0 2 0H17C17.3315 0 17.6495 0.131696 17.8839 0.366117C18.1183 0.600537 18.25 0.918479 18.25 1.25C18.25 1.58152 18.1183 1.89946 17.8839 2.13388C17.6495 2.3683 17.3315 2.5 17 2.5H2C1.66848 2.5 1.35054 2.3683 1.11612 2.13388C0.881696 1.89946 0.75 1.58152 0.75 1.25ZM0.75 7.5C0.75 7.16848 0.881696 6.85054 1.11612 6.61612C1.35054 6.3817 1.66848 6.25 2 6.25H17C17.3315 6.25 17.6495 6.3817 17.8839 6.61612C18.1183 6.85054 18.25 7.16848 18.25 7.5C18.25 7.83152 18.1183 8.14946 17.8839 8.38388C17.6495 8.6183 17.3315 8.75 17 8.75H9.5H2C1.66848 8.75 1.35054 8.6183 1.11612 8.38388C0.881696 8.14946 0.75 7.83152 0.75 7.5ZM0.75 13.75C0.75 13.4185 0.881696 13.1005 1.11612 12.8661C1.35054 12.6317 1.66848 12.5 2 12.5H9.5C9.83152 12.5 10.1495 12.6317 10.3839 12.8661C10.6183 13.1005 10.75 13.4185 10.75 13.75C10.75 14.0815 10.6183 14.3995 10.3839 14.6339C10.1495 14.8683 9.83152 15 9.5 15H2C1.66848 15 1.35054 14.8683 1.11612 14.6339C0.881696 14.3995 0.75 14.0815 0.75 13.75Z"
										fill = "#003c53"
								/>
							</svg >
						</button >
						<!--            <span-->
						<!--              *ngIf="user_role !== 'super_admin' && user_role !== 'agent'"-->
						<!--              class="location"-->
						<!--            >-->
						<!--              {{ "Location" | translate }}</span-->
						<!--            >-->
						<div style = "background-color: #ECECEC" class = "custom-field-small">
							<mat-label >{{ "Location" | translate }}</mat-label >
							<mat-select
									*ngIf = "user_role !== 'super_admin' && user_role !== 'agent'"
									[disabled] = "user_role!='manager'"
									[value] = "temLocation?._id"
									(valueChange) = "onSelectedLocationChange($event)"
									class = "custom-select"
							>
								<mat-option
										class = "location-item"
										*ngFor = "let item of locations"
										[value] = "item._id"
								>
									{{ item.name }}
								</mat-option >
							</mat-select >
						</div >
					</div >
					<div *ngIf = "user_role !== 'super_admin' && user_role !== 'agent'">
						<div
								class = "subscription-expired"
								*ngIf = "
                number(now | calculateDate : subscription_end : 'di') <= 7 &&
                number(now | calculateDate : subscription_end : 'di') >= 1
              "
						>
							<mat-icon svgIcon = "redtimer" class = "active-timer-icon"></mat-icon >
							<div >
								{{ "Subscription expire in" | translate }}
								{{ now | calculateDate : subscription_end : "uow" }}
							</div >
						</div >
						<div class = "expired" *ngIf = "expired(subscription_end)">
							<mat-icon svgIcon = "danger" class = "active-timer-icon"></mat-icon >
							<div class = "danger-text ms-1">
								{{ "Subscription expired" | translate }}
							</div >
						</div >
					</div >
					<div class = "right-box">
						<mat-select
								[value] = "translateService.currentLang"
								class = "select-language"
								(selectionChange) = "translateService.use($event.value)"
						>
							<mat-select-trigger
									class = "select-trigger"
									*ngIf = "translateService.currentLang != undefined"
							>
								<div class = "d-flex align-items-center">
									<img
											class = "flag"
											[src] = "
                      translateService.currentLang == 'en'
                        ? '/assets/imgs/eng-flag.svg'
                        : '/assets/imgs/khm-flag.svg'
                    "
									/>
									<div class = "language ms-2">
										{{ translateService.currentLang == "en" ? "ENG" : "KHM" }}
									</div >
								</div >
							</mat-select-trigger >
							<mat-option
									*ngFor = "let lang of translateService.getLangs()"
									[value] = "lang"
									(click) = "changeLanguagePreference()"
							>
								<div class = "d-flex align-items-center">
									<img
											class = "flag"
											[src] = "
                      lang == 'en'
                        ? '/assets/imgs/eng-flag.svg'
                        : '/assets/imgs/khm-flag.svg'
                    "
									/>
									<div class = "language ms-2">
										{{ lang == "en" ? "ENG" : "KHM" }}
									</div >
								</div >
							</mat-option >
						</mat-select >
						<div [matMenuTriggerFor] = "dropdown" class = "profile-box">
							<div class = "img-box me-2">
								<img
										src = "/assets/imgs/detault-user.png"
										width = "35px"
										height = "35px"
								/>
							</div >
							<div class = "user-box">
								<div class = "user">
									<p class = "name">{{ first_name }} {{ last_name }}</p >
									<mat-icon class = "icon">keyboard_arrow_down</mat-icon >
								</div >
								<p class = "role">
									{{ "Roles." + user_role | translate }}
								</p >
							</div >
						</div >
					</div >
					<mat-menu #dropdown = "matMenu" class = "logout-menu">
						<button mat-menu-item (click) = "openResetPasswordDialog()">
							<mat-icon >password</mat-icon >
							{{ "Change_password" | translate }}
						</button >
						<a mat-menu-item routerLink = "/login" (click) = "authService.logout()">
							<mat-icon >exit_to_app</mat-icon >
							{{ "Logout" | translate }}
						</a >
					</mat-menu >
				</mat-toolbar-row >
			</mat-toolbar >
		</div >
		<div
				class = "content content-margin"
				[ngStyle] = "{
        'overflow-y': isHome ? 'hidden' : 'auto',
        'background-color':
          isHome ||
          isReport ||
          isDashboard ||
          isAccountant ||
          isClients ||
          isProperties
            ? ''
            : 'white',

        padding:
          isHome ||
          isReport ||
          isDashboard ||
          isAccountant ||
          isClients ||
          isProperties
            ? ''
            : '1.5rem'
      }"
				[class.sided] = "drawerMode === 'side' && sideBarOpen"
		>
			<router-outlet ></router-outlet >
		</div >
	</mat-drawer-content >
</mat-drawer-container >
