<form (ngSubmit) = "submit()" [formGroup] = "form" class = "form-field">
	<div class = "content">
		<div class = "title" mat-dialog-title>First month rental fee</div >
		<mat-form-field appearance = "outline" class = "full-width default-input" mat-dialog-content>
			<mat-label >Rental fee</mat-label >
			<div class = "input-display">
				<input (keydown) = "validateNum($event)" (paste) = "validateNum($event)" formControlName = "rental_fee"
						matInput
						type = "text"
				/>
				<div class = "unit-size">USD</div >
			</div >
			<mat-error *ngIf = "form.controls.rental_fee.hasError('required')">
				Rental fee is <strong >required</strong >
			</mat-error >
		</mat-form-field >
	</div >
	<div align = "end" mat-dialog-actions>
		<button
				class = "primary-button px-5"
				color = "primary"
				mat-raised-button
				type = "submit"
		>
			Confirm
		</button >
	</div >
</form >
