export function validateNum(evt: KeyboardEvent | ClipboardEvent): void {
    let key: string;
    const target = evt.target as HTMLInputElement;

    if (evt.type === 'paste') {
        const clipboardData = (evt as ClipboardEvent).clipboardData;
        key = clipboardData?.getData('text/plain') || '';
    } else {
        const keyEvent = evt as KeyboardEvent;
        key = keyEvent.key;
    }

    const regex = /^[0-9.]$/;
    if (!regex.test(key) && key !== 'Backspace' && key !== 'Tab') {
        evt.preventDefault();
    }

    if (key === '.' && target.value.includes('.')) {
        evt.preventDefault();
    }
}
