import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogRoomDetailComponent } from '../dialog-room-detail/dialog-room-detail.component';

@Component({
  selector: 'app-dialog-payment-success',
  templateUrl: './dialog-payment-success.component.html',
  styleUrls: ['./dialog-payment-success.component.scss'],
})
export class DialogPaymentSuccessComponent implements OnInit {
  remainingDeposit: any;
  constructor(
    public dialogRef: MatDialogRef<DialogRoomDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'check',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/check.svg')
    );
    this.remainingDeposit = this.data.data;
  }

  ngOnInit(): void {}
}
